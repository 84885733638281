<template>
  <div id="orderFormList">
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="orderId" label="订单编号" align="center">
        </el-table-column>
        <el-table-column prop="plateNum" label="车牌号" align="center">
        </el-table-column>
        <el-table-column label="订单状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.orderStatus === '待支付'" class="payStatus"
              >待支付</span
            >
            <span
              v-if="scope.row.orderStatus === '支付取消'"
              class="payStatus dot4"
              >支付取消</span
            >
            <span
              v-if="scope.row.orderStatus === '已退款'"
              class="payStatus dot1"
              >已退款</span
            >
            <span
              v-if="scope.row.orderStatus === '支付成功'"
              class="payStatus dot2"
              >支付成功</span
            >
            <span
              v-if="scope.row.orderStatus === '支付失败'"
              class="payStatus dot3"
              >支付失败</span
            >
            <span
              v-if="scope.row.orderStatus === '交易关闭'"
              class="payStatus dot4"
              >交易关闭</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="parkingLotName"
          label="停车场名称"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="totalPrice" label="订单总金额" align="center">
        </el-table-column>
        <el-table-column prop="couponPrice" label="优惠券金额" align="center">
        </el-table-column>
        <el-table-column
          prop="payAmount"
          label="实际支付金额"
          align="center"
          width="100px"
        >
        </el-table-column>
        <el-table-column
          prop="serviceStartTime"
          label="入场时间"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="serviceEndTime" label="出场时间" align="center">
        </el-table-column>
        <el-table-column prop="parkingTime" label="停车时长" align="center">
        </el-table-column>
        <el-table-column prop="priceName" label="套餐名称" align="center">
        </el-table-column>
        <el-table-column prop="priceVersion" label="套餐类型" align="center">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filtrate: {
        keyword: "",
        date: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    reset() {
      this.filtrate.keyword = "";
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/user/order/list", {
          keyword: this.filtrate.keyword,
          pageNum: page,
          pageSize: this.table.pageSize,
          unionId: this.$route.params.id,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.serviceStartTime = this.$moment(item.serviceStartTime).format(
              "yyyy-MM-DD HH:mm:ss"
            );
            item.serviceEndTime = this.$moment(item.serviceEndTime).format(
              "yyyy-MM-DD HH:mm:ss"
            );
          }
          Object.assign(this.table, {
            tableData: res.data.list,
            total: res.data.total,
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#orderFormList {
  .table-container {
    .el-table {
      .el-table__body {
        .payStatus {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #8d8d8d;
          &::before {
            content: "";
            display: inline-block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #8d8d8d;
            margin-right: 5px;
          }
        }
        .dot1 {
          color: #f0ad30;
          &::before {
            background-color: #f0ad30;
          }
        }
        .dot2 {
          color: $main-color;
          &::before {
            background-color: $main-color;
          }
        }
        .dot3 {
          color: #ff0000;
          &::before {
            background-color: #ff0000;
          }
        }
        .dot4 {
          color: #a7a2a2;
          &::before {
            background-color: #a7a2a2;
          }
        }
      }
    }
  }
}
</style>
